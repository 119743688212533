/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}


body{
    overflow-x: hidden;
}
/* Custom Scroll Bar */
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background-color: #FFFFFF;
}
::-webkit-scrollbar-thumb {
    background-color: #600000;
    border-radius: 4rem;
}
