.dropdown {
    position: relative;
    display: inline-block;
  }
  
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}
  
.dropdown:hover .dropdown-content {
    display: block;
}

.dropbtn {
    background-color: #4CAF50;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    padding: 0;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #f1f1f1}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }

.navbar-section {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: white;
    position: fixed;
    width: 100%;
    z-index: 1;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
}
.navbar-title a {
    color: #a60000;
    letter-spacing: .6px;
    text-decoration: none;
    margin-left: 3px;
}
.navbar-title {
    font-family:"Brush Script MT Italic V2";
    display: flex;
    align-items: center;
    font-size: 2rem;

}
.navbar-sign {
    color: #a60000;
    font-family: Cambria, sans-serif;
    font-size: 40px;
    font-weight: bold;
}
.navbar-items {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding-top: 1rem;
    font-family: 'Rubik', sans-serif;
}
.navbar-items > li {
    list-style-type: none;
}
.navbar-links {
    text-decoration: none;
    color: black;
    font-size: 18px;
    letter-spacing: .8px;
}
.navbar-links:hover {
    color: #ca2715;
}
.navbar-btn {
    padding: 14px 20px;
    color: white;
    border: 1px solid transparent;
    border-radius: 28px;
    outline: transparent;
    background-color: #a60000;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}
.navbar-btn:hover {
    color: #a60000;
    background-color: white;
    border: 1px solid #a60000;
}
/* Hamburger Icon */
.mobile-nav {
    display: none;
}
.hamb-icon {
    width: 26px;
    height: 26px;
    cursor: pointer;
}
.hamb-icon:hover {
    color: #7E170C;
}
/* Mobile Navbar */
.mobile-navbar {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    background-color: white;
    z-index: 20;
    justify-content: center;
    align-items: center;
    transition: left .5s ease-in-out;
}
.mobile-navbar-close {
    position: absolute;
    top: 28px;
    right: 28px;
}
.mobile-navbar-close .hamb-icon:hover {
    color: rgb(255, 22, 22);
}
.open-nav {
    left: 0;
}
.mobile-navbar-links {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    gap: 24px;
    /* margin-bottom: 24px; */
    /* margin-top: 24px; */

    text-align: center;
}
.mobile-navbar-links li a {
    text-decoration: none;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: .8px;
    transition: color .3s ease-in-out;
}
.mobile-navbar-links li a:hover {
    color: #7E170C;
}

.logo{
    aspect-ratio: 1/1;
    width:4rem;
    /* display: none; */
    /* height: auto; */
    /* mix-blend-mode: color-burn; */
}
/* Responsive */
@media screen and (max-width: 1000px) {
    .navbar-btn, .navbar-items {
        display: none;
    }
    .mobile-nav {
        display: block;
    }
    
}
@media screen and (max-width: 900px) {
    .navbar-btn, .navbar-items {
        display: none;
    }
    .mobile-nav {
        display: block;
    }
    .logo{
        display:none
    }
}
