.hero-section {
    padding: 28px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    overflow: hidden;
    /* gap: 24px; */
    /* background:  #fff9f9; */
    /* height: 100vh; */
}
/* Text section */
.text-section {
    width: 60%;
    padding: 0 32px;
}
.text-headline {
    margin-bottom: 12px;
    color: rgb(255, 255, 255);
    font-family: 'Rubik';
    font-size: 22px;
    font-weight: bold;
    letter-spacing: .7px;
    text-shadow: rgb(37, 37, 37) 1px 0 10px;
}
.text-title {
    
    color: rgb(255, 255, 255);
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
    text-shadow: rgb(37, 37, 37) 1px 0 10px;
}
.text-descritpion {
    width: 475px;
    margin: 32px 0;
    color: rgb(255, 255, 255);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .7px;
    line-height: 1.6rem;
    text-shadow: rgb(37, 37, 37) 1px 0 10px;
}
.text-appointment-btn {
    padding: 14px 20px;
    color: white;
    border: 1px solid transparent;
    border-radius: 28px;
    outline: transparent;
    background-color: #A60000;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
    margin-top: 25px;
}
.text-appointment-btn:hover {
    color: #A60000;
    background-color: transparent;
    border: 1px solid #A60000;
}
.text-stats {
    margin-top: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
}
.text-stats-container {
    text-align: center;
}
.text-stats-container > p {
    color: rgb(255, 255, 255);
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    letter-spacing: .7px;
    text-shadow: rgb(37, 37, 37) 1px 0 10px;
}
.text-stats-container p:first-child {
    margin-bottom: 8px;
    color: #e34e4e;
    font-family: 'Rubik', sans-serif;
    font-size: 32px;
    font-weight: bold;
    text-shadow: rgb(37, 37, 37) 1px 0 10px;
}
/* Image section */
.hero-image-section {
    width: 40%;
    height: auto;
    /* max-width: 100%; */
    text-align: center;
}
.hero-image1 {
    width: 300px;
    
    height: auto;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

}
/* Scroll Btn */
.scroll-up {
    width: 45px;
    height: 45px;
    display: none;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
    border: 3px solid white;
    border-radius: 50%;
    background-color: #A60000;
    position: fixed;
    bottom: 50px;
    right: 50px;
    font-size: 24px;
    z-index: 20;
    cursor: pointer;
}  
.show-scroll {
  display: flex;
}
/* Responsive */
@media screen and (max-width: 900px) {
    .hero-image-section {
        display: none;
    }
    .text-section {
        width: 100%;
        padding: 0;
    }
    .text-title, .text-descritpion {
        width: 100%;
    }
}
@media screen and (max-width: 600px) {
    .text-headline {
        font-size: 20px;
    }
    .text-title {
        font-size: 28px;
    }
    .text-descritpion {
        font-size: 16px;
    }
    .text-appointment-btn {
        font-size: 16px;
    }
    .text-stats {
        gap: 18px;
    }
    .text-stats-container > p {
        font-size: 16px;
    }
    .text-stats-container p:first-child {
        font-size: 22px;
    }
    .scroll-up {
        bottom: 32px;
        right: 32px;
    }
}
