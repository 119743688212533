.faculty-table {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    margin-top: 2rem;
  }
  
  .faculty-table th,
  .faculty-table td {
    border: 1px solid #ddd;
    padding: 8px;
    vertical-align: top;
  }

  .faculty-table td a{
    cursor: pointer;
  }
  
  .faculty-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .faculty-table .name-cell {
    display: flex;
    flex-direction: column;
  }
  
  .faculty-table .name {
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .faculty-table .details p {
    margin: 0;
  }
  
  .faculty-table .profile-pic {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
  