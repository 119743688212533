.info-section {
    padding: 0 32px;
    text-align: center;
    background-color: white;
}
.info-title-content {
    /* margin: 64px 0 60px 0; */
}
.info-title {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: bold;
}
.info-title > span {
    position: relative;
}
.info-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #9D2553;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}
.info-description {
    margin-left: 64px;
    margin-right: 64px;

    text-align: center;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .8px;
    line-height: 1.8rem;
}



  #gallery{
    -webkit-column-count:4;
    -moz-column-count:4;
    column-count:4;
    
    -webkit-column-gap:20px;
    -moz-column-gap:20px;
    column-gap:20px;
  }
  @media (max-width:1200px){
    #gallery{
    -webkit-column-count:3;
    -moz-column-count:3;
    column-count:3;
      
    -webkit-column-gap:20px;
    -moz-column-gap:20px;
    column-gap:20px;
  }
  }
  @media (max-width:800px){
    #gallery{
    -webkit-column-count:2;
    -moz-column-count:2;
    column-count:2;
      
    -webkit-column-gap:20px;
    -moz-column-gap:20px;
    column-gap:20px;
  }
  }
  @media (max-width:600px){
    #gallery{
    -webkit-column-count:1;
    -moz-column-count:1;
    column-count:1;
  }  
  }
  #gallery img,#gallery video {
    width:100%;
    height:auto;
    margin: 4% auto;
    box-shadow:-3px 5px 15px #000;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  .modal-img,.model-vid{
    width:100%;
    /* position: fixed; */
    /* z-index: 1; */
    margin: 0;
    padding: 0;
    height:auto;
  }
  .modal-body{
    padding:0;
    margin: 0;
  }

  .button {
    position: fixed;
    width: 2rem;
    margin: 6px;
    font-size: 1rem;
    height: 2rem;
    border: none;
    background: rgba(180, 83, 107, 0.11);
    border-radius: 50%;
    transition: background 0.5s;
    z-index: 1;
  }
  
  .X {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1rem;
    height: 1.5px;
    background-color: rgb(255, 255, 255);
    transform: translateX(-50%) rotate(45deg);
  }
  
  .Y {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1rem;
    height: 1.5px;
    background-color: #fff;
    transform: translateX(-50%) rotate(-45deg);
  }
  
  .close {
    position: absolute;
    display: flex;
    padding: 0.8rem 1.5rem;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
    top: -70%;
    left: 50%;
    width: 2rem;
    height: 1.7em;
    font-size: 0.5rem;
    background-color: rgb(19, 22, 24);
    color: rgb(187, 229, 236);
    border: none;
    border-radius: 3px;
    pointer-events: none;
    opacity: 0;
  }
  
  .button:hover {
    background-color: rgb(211, 21, 21);
  }
  
  .button:active {
    background-color: rgb(130, 0, 0);
  }
  
  .button:hover > .close {
    animation: close 0.2s forwards 0.25s;
  }
  
  @keyframes close {
    100% {
      opacity: 1;
    }
  }
  